import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#f4f4f4">
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Menü</h1>
        {Array.from(Array(20).keys()).map((i) => (
          <img style={{ marginTop: 24 }} src={`/menu/${i}.png`} alt="Menu" />
        ))}
      </AnimatedBox>
    </Layout>
  )
}

export default About
